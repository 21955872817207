@import url('https://fonts.googleapis.com/css2?family=Freehand&family=Leckerli+One&family=Marmelad&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Snippet&display=swap');
@font-face {
  font-family: "Constellation", "handwriting";
  src: url("./assets/fonts/the_constellation/The Constellation.ttf");
  src: url("./assets/fonts/handwriting/Handwriting-Regular.otf")
      format("embedded-opentype"),
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

/* scrollBar */

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(132deg, #dfe6e8 0%, #08779c 100%);
  border-radius: 2rem;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  body {
    overflow-x: hidden;
  }
}

.slick-track {
  display: flex;
  column-gap: 10px;
}
