.navbar {
  height: 3.5rem;
  position: absolute;
  background-color: transparent;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.makeStyles-navMenu-1 {
  cursor: pointer;
  text-shadow: 0px 0px 1px rgb(27, 76, 174), 0 0 1em #505557, 0 0 0.1em rgb(76, 108, 214);
  box-shadow: 5px 5px 5px 1px rgba(1, 9, 20, .4);

}

.navbar--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 3rem;
  margin-top: 1rem;
}

.navbar--container > h1 {
  font-family: "Freehand";
  text-shadow: 0 1px 1px #fcfeff,0 0 0.1em #818994,0 0 .1em #0a0a0a;
  color: var(--primary-2);
  font-size: 4rem;
  font-weight: 400;
  user-select: none;
}

.navLink--container {
  margin-top: 5rem;
  align-items:center;
}

.drawer > .MuiBackdrop-root {
  background: rgba(33, 33, 33, 0.15) !important;
  backdrop-filter: blur(20px) !important;
}

.drawer > .MuiDrawer-paper {
  background-color: #15202b !important;
}

.navbar--container > svg {
    margin-top: -100px;
    margin-right: -15px;
    box-shadow: 0 0 12px 2px #0d68c3;
    border-radius: 30px;
}

@media (max-width: 1100px) {
  .navbar--container {
    padding: 0 2rem;
  }
  .navbar--container > h1 {
    font-size: 2.8rem;
  }
  .nav-menu {
    font-size: 2.2rem;
    margin-top: -1.5rem;
  }
}

@media (max-width: 800px) {
  .navLink--container {
    margin-top: 3rem;
  }
}

@media (max-width: 600px) {
  .nav-menu {
    color: var(--primary-2);
  }
  .navbar--container > h1 {
    font-size: 2rem;
  }
  .nav-menu:hover {
    color: var(--primary-2);
  }
  .MuiDrawer-paper {
    border-radius: 0 !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .navbar {
    height: 5rem;
  }
  .navbar--container {
    padding: 0 1rem;
    margin-top: 0rem;
  }
  .navbar--container > h1 {
    font-size: 1.5rem;
  }
}
