.singleProject {
  box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
  width: 304px;
  height: 360px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2.2rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 0px 12px 2px rgb(13, 104, 195);
}

.projectContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.projectContent > h2 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.525rem;
  line-height: 110%;
  text-align: center;
  text-shadow: 0 0px 0px #acbcc2,0 0 .1em #818994,0 0 0.1em #434447;
}

.projectContent > img {
  width: 100%;
  height: 60%;
  transition: opacity 0.7s 0.3s;
}

.projectContent > img  {
  overflow: hidden;
}

.projectContent:hover img {
width:100%;
-webkit-transform:scale(1.1);
transform:scale(1.1);
-webkit-transition:0.8s ease-in-out;
transition:0.8s ease-in-out;
}

.project--showcaseBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.project--showcaseBtn > a {
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.5s 0.2s;
  box-shadow: 0px 0px 12px 2px rgb(13, 104, 195);
}

.singleProject:hover .project--showcaseBtn a:nth-child(2) {
  transform: translateX(-140px) scale(1.1) !important;
}

.singleProject .project--desc {
  position: absolute;
  width: 95%;
  height: 160px;
  left: 0;
  top: 0;
  transform: translate(-110%, 40%);
  padding: 15px;
  border-radius: 0 20px 20px 0;
  transition: transform 0.9s;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--primaryFont);
}

.singleProject:hover .project--desc {
  transform: translate(-2%, 40%);
}

.singleProject .project--lang {
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 140px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 0 12px 2px #0d68c3;
  transform: translateX(100%);
  transition: transform 0.5s 0.3s;
  padding: 0.825rem;
  text-shadow: 0 0 1px #46494e, 0 0 0.1em #8f949a, 0 0 0.1em #4b4f56;
}

.singleProject:hover .project--lang {
  transform: translateX(0);
}

.project--lang > span {
  font-family: var(--primaryFont);
  font-weight: 500;
  word-break: break-word;
  line-height: 100%;
}
